@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-datepicker/dist/react-datepicker';

@font-face {
  src: local('src/assets/font/SukhumvitSet-Bold.ttf');
  font-family: 'SukhumvitSet-Bold';
}

@font-face {
  src: local('src/assets/font/SukhumvitSet-Text.ttf');
  font-family: 'SukhumvitSet-Text';
}

@font-face {
  src: local('src/assets/font/SukhumvitSet-Light.ttf');
  font-family: 'SukhumvitSet-Light';
}

@font-face {
  src: local('src/assets/font/SukhumvitSet-Medium.ttf');
  font-family: 'SukhumvitSet-Medium';
}

@font-face {
  src: local('src/assets/font/SukhumvitSet-Thin.ttf');
  font-family: 'SukhumvitSet-Thin';
}

* {
  font-family: 'SukhumvitSet-Text', sans-serif;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  scroll-behavior: smooth;
  /* background: rgb(32,52,116);
  background: linear-gradient(180deg, rgb(31,52,113) 0%, rgb(31,53,115) 40%, rgb(33,56,122) 50%, rgb(36,61,134) 60%, rgb(40,68,152) 65%, rgb(45,77,174) 70%, rgb(52,88,202) 80%, rgb(60,103,238) 100%); */
}